import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';

const ShowDiagnostics = function(header, body) {
    $("#message-header").html(header);
    $("#message-body").html("<p>"+body+"</p>");
    $("#myModal").modal();
};

export {ShowDiagnostics};
