import $ from 'jquery';

const LoginRender = function(signinstatus) {
    if (signinstatus === "servicedown") {
	$("#login-div").hide();
	$("#introduction-div").hide();
	$("#login-msg-div").show();
	$('#loginmessage').html("Service temporarily unavailable.");
    } else {
	$("#login-div").show();
	$("#introduction-div").show();
	$("#login-msg-div").show();
	$("#logout_link").hide();
	$('#loginmessage').html(signinstatus);
	$('#app-info-div').show();
    }
    $("#df-feature-level").hide();
};

const updateElementVisibility = function (showScopeOpt) {
    //$("#op-mode-div").show();
    document.getElementById("opMode").style.visibility = "visible";
    if (showScopeOpt) {
	//document.getElementById("index-custom-file").style.visibility = "visible";
	document.getElementById("CustomKBOps").style.visibility = "visible";
	document.getElementById("ViewControl").style.visibility = "visible";
    } else {
	//document.getElementById("index-custom-file").style.visibility = "hidden";
	document.getElementById("CustomKBOps").style.visibility = "hidden";
	document.getElementById("ViewControl").style.visibility = "hidden";
	// $("#scopeOptions").hide();
    }
    document.getElementById("code-submit").style.visibility = "visible";
    document.getElementById("move-down").style.visibility = "visible";
    document.getElementById("move-up").style.visibility = "visible";
    document.getElementById("src-info-display").style.visibility = "visible";
    document.getElementById("minfo-display").style.visibility = "visible";
    document.getElementById("busy-indicator").style.visibility = "visible";
    document.getElementById("logout_link").style.visibility = "visible";
    document.getElementById("file-list").style.visibility = "visible";
    document.getElementById("working-area-div").style.visibility = "visible";
};

const ProtectRender = function () {
    $("#code-container").hide();
    $("#dragBar").hide();
    // $("#mf-table").hide();
    $("#match-result-container").hide();
    $("#opMode").hide();
    $("#dark_mode").hide();
    $('#CustomKBOps').hide();
    $('#ViewControl').hide();
    $('#file-list').hide();
}
  
export {LoginRender, ProtectRender, updateElementVisibility};
