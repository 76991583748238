import $ from 'jquery';

var OPModePrevSelected = function() {
    var prev = document.getElementById("opMode").innerHTML;
    var pos = prev.indexOf("(Project)");
    if (pos === -1) {
	return "snippet";
    } else {
	return "file";
    }
}

var OPModeSwitch = function (type, user_t) {

    if (type === 'file') {	

	document.getElementById("opMode").innerHTML = '<i class="fa"></i> Mode (Project) <i class="fa fa-caret-down"></i>';
	document.getElementById("fileMode").innerHTML = '<i class="fa fa-check"></i> Project';
	document.getElementById("snippetMode").innerHTML = 'Snippet';
	$("#file-list").show();
	document.getElementById("src-code-area").classList.remove("col-sm-6");
	document.getElementById("match-code-area").classList.remove("col-sm-6");
	if ($("#file-list").hasClass("col-sm-2")) {
	    document.getElementById("src-code-area").classList.add("col-sm-5");	    
	    document.getElementById("match-code-area").classList.add("col-sm-5");
	} else if ($("#file-list").hasClass("col-sm-3")) {
	    document.getElementById("src-code-area").classList.add("col-sm-4");	    
	    document.getElementById("match-code-area").classList.add("col-sm-5");
	} else if ($("#file-list").hasClass("col-sm-4")) {
	    document.getElementById("src-code-area").classList.add("col-sm-4");	    
	    document.getElementById("match-code-area").classList.add("col-sm-4");
	}
	$("#dbScope").show();
	
	document.getElementById("clear-code").style.visibility = "hidden";
	document.getElementById("code-submit").style.visibility = "hidden";
	
	//if (user_t !== 'p') { 
	//    document.getElementById("code-submit").style.visibility = "hidden";
	//} else {
	//    document.getElementById("code-submit").style.visibility = "visible";
	//}
	$('#src-info-display').html('');
    } else if (type === 'snippet') {
	document.getElementById("clear-code").style.visibility = "visible";
	document.getElementById("opMode").innerHTML = '<i class="fa"></i> Mode (Snippet) <i class="fa fa-caret-down"></i>';
	document.getElementById("fileMode").innerHTML = 'Project';
	document.getElementById("snippetMode").innerHTML = '<i class="fa fa-check"></i> Snippet';
	$("#file-list").hide();
	
	document.getElementById("code-submit").style.visibility = "visible";

	document.getElementById("src-code-area").classList.remove("col-sm-5");
	document.getElementById("src-code-area").classList.add("col-sm-6");
	document.getElementById("match-code-area").classList.remove("col-sm-5");
	document.getElementById("match-code-area").classList.add("col-sm-6");
	$('#src-info-display').html('source code snippet');
	$("#dbScope").hide();
	
	window.sessionStorage.setItem("cached_src_file", 'source code snippet');

    } else {
	alert('No such search type: ' + type);
    }

}

export {OPModePrevSelected, OPModeSwitch};
