import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import $ from 'jquery';

var dtRowColor = "#212529";
var frontColor = "#ffffff";
let backgroundColor = "black";
let rootBackgroundColor = '#f6f6f6';
let projectContainerBackgroundColor = '#343a40';
let cacheColor = "color:#00ff00";

const ThemePrevSelected = function() {
    let prev = document.getElementById("dark_mode").innerHTML;
    let pos = prev.indexOf("fa-sun");
    if (pos === -1) {
	return "light";
    } else {
	return "dark";
    }
}

const ThemeSwitch = function (type) {

    console.log(type);
    
    if (type === 'dark') {
	dtRowColor = '#212529';
	frontColor = "white";
	backgroundColor = "black";
	cacheColor = "color:#00ff00";
	
	document.getElementById("root-body").style.background = rootBackgroundColor;
	document.getElementById("protect_tab").style.color = frontColor;
	document.getElementById("logout_link").style.color = frontColor;

	monaco.editor.setTheme('vs-dark');
	document.getElementById("navheader").classList.add('bg-dark');
	document.getElementById("navheader").classList.add('navbar-dark');
	document.getElementById("file-list").style.background = backgroundColor;
	document.getElementById("file-list").style.color = frontColor;
	document.getElementById("flist").style.color = frontColor;
	
	document.getElementById("file-list-header").style.color = frontColor;
	document.getElementById("job-table").style.background = backgroundColor;
	document.getElementById("job-table").style.color = frontColor;
	document.getElementById("project-info-container").style.background = projectContainerBackgroundColor;
	document.getElementById("all-view-chart-container").style.background = "#242424";
	

	let aTds = document.getElementById("all-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("allprojectsview").classList.add('table-dark');
	document.getElementById("allprojectsview-thead").classList.add('thead-dark');

	aTds = document.getElementById("component-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("componentview").classList.add('table-dark');
	document.getElementById("componentview-thead").classList.add('thead-dark');
	aTds = document.getElementById("dependency-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("dependencyview").classList.add('table-dark');
	document.getElementById("dependencyview-thead").classList.add('thead-dark');
	aTds = document.getElementById("license-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("licenseview").classList.add('table-dark');
	document.getElementById("licenseview-thead").classList.add('thead-dark');

	aTds = document.getElementById("cve-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("cveview").classList.add('table-dark');
	document.getElementById("cveview-thead").classList.add('thead-dark');

	aTds = document.getElementById("vul-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("vulview").classList.add('table-dark');
	document.getElementById("vulview-thead").classList.add('thead-dark');

	aTds = document.getElementById("staticresult").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	
	document.getElementById("staticresult").classList.add('table-dark');
	document.getElementById("staticresult-thead").classList.add('thead-dark');

	// flist items
	const flist_items = document.getElementsByClassName("flist-item");
	for (var k=0; k<flist_items.length; k++) {
	    flist_items[k].getElementsByTagName("a")[0].style.color = frontColor;
	    // flist_items[k].style.color = 'white';
	}
	// flist delete items
	const flist_dels = document.getElementsByClassName("flist-delete");
	for (let k=0; k<flist_dels.length; k++) {
	    flist_dels[k].style.color = frontColor;
	}
	
	const btn = document.getElementsByClassName("dropbtn");
	for (k=0; k<btn.length; k++) {
	    btn[k].style.color = frontColor;
	}
	
	document.getElementById("matchedfiles").style.background = backgroundColor;

	const mTds = document.getElementById("match-table-container").getElementsByTagName("td");
	for (let k=0; k<mTds.length; k++) {
	    mTds[k].style.background = dtRowColor;
	    mTds[k].style.color = frontColor;
	    const links = mTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("matchedfiles").classList.add('table-dark');
	document.getElementById("matchedfiles-thead").classList.add('thead-dark');

	
	//document.getElementsByClassName("card")[0].style.background = "#333333";
	//document.getElementsByClassName("card")[0].style.color = frontColor;
    } else if (type === 'light') {
	dtRowColor = "#eeeeee"; //'white';
	frontColor = "black";
	backgroundColor = "#eeeeee"; //"white";
	cacheColor = "color:#006400";
	
	document.getElementById("root-body").style.background = rootBackgroundColor;
	document.getElementById("protect_tab").style.color = frontColor;
	document.getElementById("logout_link").style.color = frontColor;
	document.getElementById("navheader").classList.remove('bg-dark');
	document.getElementById("navheader").classList.remove('navbar-dark');
	monaco.editor.setTheme('vs');
	document.getElementById("file-list").style.background = backgroundColor;
	document.getElementById("file-list").style.color = frontColor;
	document.getElementById("flist").style.color = frontColor;
		
	document.getElementById("file-list-header").style.color = frontColor;
	document.getElementById("job-table").style.background = backgroundColor;
	document.getElementById("job-table").style.color = frontColor;
	document.getElementById("project-info-container").style.background = backgroundColor;
	document.getElementById("all-view-chart-container").style.background = backgroundColor;
	
	let aTds = document.getElementById("all-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("allprojectsview").style.background = backgroundColor;

	document.getElementById("allprojectsview").classList.remove('table-dark');
	document.getElementById("allprojectsview-thead").classList.remove('thead-dark');

	aTds = document.getElementById("component-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("componentview").style.background = backgroundColor;

	document.getElementById("componentview").classList.remove('table-dark');
	document.getElementById("componentview-thead").classList.remove('thead-dark');

	aTds = document.getElementById("dependency-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("dependencyview").style.background = backgroundColor;

	document.getElementById("dependencyview").classList.remove('table-dark');
	document.getElementById("dependencyview-thead").classList.remove('thead-dark');
	
	aTds = document.getElementById("license-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("licenseview").style.background = backgroundColor;

	document.getElementById("licenseview").classList.remove('table-dark');
	document.getElementById("licenseview-thead").classList.remove('thead-dark');

	aTds = document.getElementById("cve-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("cveview").style.background = backgroundColor;

	document.getElementById("cveview").classList.remove('table-dark');
	document.getElementById("cveview-thead").classList.remove('thead-dark');

	aTds = document.getElementById("vul-view-table").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("vulview").style.background = backgroundColor;

	document.getElementById("vulview").classList.remove('table-dark');
	document.getElementById("vulview-thead").classList.remove('thead-dark');

	aTds = document.getElementById("staticresult").getElementsByTagName("td");
	for (let k=0; k<aTds.length; k++) {
	    aTds[k].style.background = dtRowColor;
	    aTds[k].style.color = frontColor;
	    const links = aTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	
	document.getElementById("staticresult").classList.add('table-dark');
	document.getElementById("staticresult-thead").classList.add('thead-dark');

	const flist_items = document.getElementsByClassName("flist-item");
	for (let k=0; k<flist_items.length; k++) {
	    flist_items[k].getElementsByTagName("a")[0].style.color = frontColor;
	}
	
	const flist_dels = document.getElementsByClassName("flist-delete");
	for (let k=0; k<flist_dels.length; k++) {
	    flist_dels[k].style.color = frontColor;
	}

	var btn = document.getElementsByClassName("dropbtn");
	for (k=0; k<btn.length; k++) {
	    btn[k].style.color = frontColor;
	}
	document.getElementById("matchedfiles").style.background = backgroundColor;
	var mTds = document.getElementById("match-table-container").getElementsByTagName("td");
	for (let k=0; k<mTds.length; k++) {
	    mTds[k].style.background = dtRowColor;
	    mTds[k].style.color = frontColor;

	    const links = mTds[k].getElementsByTagName("a");
	    for (let idx = 0; idx < links.length; idx++) {
		links[idx].style.color = frontColor;
	    }
	}
	document.getElementById("matchedfiles").classList.remove('table-dark');
	document.getElementById("matchedfiles-thead").classList.remove('thead-dark');
	//document.getElementsByClassName("card")[0].style.background = "#e0e0e0";
	//document.getElementsByClassName("card")[0].style.color = frontColor;
    } else {
	alert('No such theme type: ' + type);
    }
    document.getElementById("dark_mode").style.color = frontColor;
}

export {ThemePrevSelected, ThemeSwitch, dtRowColor, frontColor, cacheColor};
